<template>
  <div class="lg:flex lg:space-x-10">
    <div class="w-full space-y-7">
      <div class="card p-3">
        <discussion-box/>
      </div>

      <div class="card" v-for="forum in forums.data" :key="'forum' + forum.id">
        <div class="card-body">
          <div class="sm:my-6 my-3 flex items-center justify-between border-b pb-3">
            <div>
              <h2 class="text-xl font-semibold">
                <router-link :to="{name: 'ForumDetail', params: {id: forum.id, slug: forum.slug}}">
                  {{ forum.title }}
                </router-link>
              </h2>
              <p class="font-medium text-gray-500 leading-6">{{ forum.content }}</p>
            </div>
          </div>
          <div class="grid md:grid-cols-2 divide">
            <div class="" v-if="forum.children && forum.children.data">
              <div class="mb-1" v-for="children in forum.children.data" :key="'forum' + children.id">
                <router-link :to="{name: 'ForumDetail', params: {id: children.id, slug: children.slug}}">
                  {{ children.title }}
                </router-link>
              </div>
            </div>
            <div v-if="forum.discussionsThrough && forum.discussionsThrough.data">
              <li v-for="topic in forum.discussionsThrough.data" :key="'topic' + topic.id">
                <router-link :title="topic.title" :to="{name: 'DiscussionDetail', params: {id: topic.id, slug: topic.slug}}">
                  {{ topic.title }}
                </router-link>
              </li>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import DiscussionBox from "./DiscussionBox";
import {censorBadWords} from "../../core/services/utils.service";

export default {
  name: 'ForumIndex',
  components: {
    DiscussionBox
  },
  data() {
    return {
      forums: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadForums() {
      let query = `query {
        forums(first: 100, where: {AND: [{column: "parent_id", value: null}]}, orderBy: [{column: "position", order: ASC}]) {
          data {
            id
            slug
            title
            content
            created_at
            children(first: 100) {
              data {
                id
                slug
                title
                content
              }
            }
            discussionsThrough(first: 10, orderBy: [{column: "position", order: DESC}], where: {AND: [{column: "status", value: 1}]}) {
              data {
                id
                slug
                title
              }
            }
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.forums) {
              this.forums = data.data.forums;
              if (this.forums.data && this.forums.data.length) {
                this.forums.data.map((forum) => {
                  if (forum.discussionsThrough && forum.discussionsThrough.data) {
                    forum.discussionsThrough.data.map((item) => {
                      item.title = censorBadWords(item.title);
                    });
                  }
                });
              }
            }
          })
          .catch((response) => {
            console.log(response);
          });
    }
  },
  mounted() {
    this.loadForums();
  }
}
</script>
